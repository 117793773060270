import validate from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/e-reader-fe/e-reader-fe/middleware/auth.global.ts";
import manifest_45route_45rule from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-callback": () => import("/home/runner/work/e-reader-fe/e-reader-fe/middleware/auth-callback.ts"),
  reader: () => import("/home/runner/work/e-reader-fe/e-reader-fe/middleware/reader.ts"),
  "trial-signup": () => import("/home/runner/work/e-reader-fe/e-reader-fe/middleware/trial-signup.ts")
}