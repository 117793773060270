import { defineStore } from 'pinia';
import { GrowthBook } from '@growthbook/growthbook';
import {
  HIGHLIGHTED_DEFAULT_TEXT_COLOR,
  HIGHLIGHT_DEFAULT_COLOR_1,
  HIGHLIGHT_DEFAULT_COLOR_2,
  HIGHLIGHT_DEFAULT_COLOR_3,
  HIGHLIGHT_DEFAULT_COLOR_4,
} from '@/utils/constants';
import { isValidHexColor } from '@/utils/stringUtils';
import { eFEATURE_FLAG, eHIGHLIGHT_COLOR } from '@/types/enums';
import { computeRelativeHSLColor } from '@/utils/css';

export const useFeatureFlagStore = defineStore('feature-flag', () => {
  // state
  const growthbook = shallowRef<GrowthBook | null>(null);

  //actions
  async function initStore() {
    const config = useRuntimeConfig();

    growthbook.value = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: config.public.growthBookKey,
      enableDevMode: process.env.NODE_ENV === 'development',
      subscribeToChanges: true,
      trackingCallback: (experiment, result) => {
        console.log('Viewed Experiment', {
          experimentId: experiment.key,
          variationId: result.key,
        });
      },
    });

    await growthbook.value.init();
  }

  function setDefaultHighlightColors() {
    const configColors = [
      {
        cssVariableName: '--reader-highlighted-text',
        color: growthbook.value?.getFeatureValue(
          eFEATURE_FLAG.HIGHLIGHT_COLOR,
          HIGHLIGHTED_DEFAULT_TEXT_COLOR
        ),
      },
      {
        cssVariableName: '--app-color-highlight-color-1',
        baseColor: growthbook.value?.getFeatureValue(
          eFEATURE_FLAG.HIGHLIGHT_COLOR_1,
          HIGHLIGHT_DEFAULT_COLOR_1
        ),
        relativeColors: [
          {
            namePosfix: '',
            colorDiff: [0, 0, 0],
          },
          {
            namePosfix: '-opacity-20',
            colorDiff: [0, 0, 0, 0.2],
          },
          {
            namePosfix: '-opacity-40',
            colorDiff: [0, 0, 0, 0.4],
          },
          {
            namePosfix: '-light',
            colorDiff: [0, -1, 54],
          },
          {
            namePosfix: '-lighter',
            colorDiff: [3, -4, 62],
          },
        ],
      },
      {
        cssVariableName: '--app-color-highlight-color-2',
        baseColor: growthbook.value?.getFeatureValue(
          eFEATURE_FLAG.HIGHLIGHT_COLOR_2,
          HIGHLIGHT_DEFAULT_COLOR_2
        ),
        relativeColors: [
          {
            namePosfix: '',
            colorDiff: [0, 0, 0],
          },
          {
            namePosfix: '-opacity-20',
            colorDiff: [0, 0, 0, 0.2],
          },
          {
            namePosfix: '-opacity-40',
            colorDiff: [0, 0, 0, 0.4],
          },
          {
            namePosfix: '-light',
            colorDiff: [0, 1, 50],
          },
          {
            namePosfix: '-lighter',
            colorDiff: [0, 0, 60],
          },
        ],
      },
      {
        cssVariableName: '--app-color-highlight-color-3',
        baseColor: growthbook.value?.getFeatureValue(
          eFEATURE_FLAG.HIGHLIGHT_COLOR_3,
          HIGHLIGHT_DEFAULT_COLOR_3
        ),
        relativeColors: [
          {
            namePosfix: '',
            colorDiff: [0, 0, 0],
          },
          {
            namePosfix: '-opacity-20',
            colorDiff: [0, 0, 0, 0.2],
          },
          {
            namePosfix: '-opacity-40',
            colorDiff: [0, 0, 0, 0.4],
          },
          {
            namePosfix: '-light',
            colorDiff: [14, 1, 48],
          },
          {
            namePosfix: '-lighter',
            colorDiff: [13, -4, 55],
          },
        ],
      },
      {
        cssVariableName: '--app-color-highlight-color-4',
        baseColor: growthbook.value?.getFeatureValue(
          eFEATURE_FLAG.HIGHLIGHT_COLOR_4,
          HIGHLIGHT_DEFAULT_COLOR_4
        ),
        relativeColors: [
          {
            namePosfix: '',
            colorDiff: [0, 0, 0],
          },
          {
            namePosfix: '-opacity-20',
            colorDiff: [0, 0, 0, 0.2],
          },
          {
            namePosfix: '-opacity-40',
            colorDiff: [0, 0, 0, 0.4],
          },
          {
            namePosfix: '-light',
            colorDiff: [5, 34, 36],
          },
          {
            namePosfix: '-lighter',
            colorDiff: [5, 34, 38],
          },
        ],
      },
    ];

    configColors.forEach(({ cssVariableName, baseColor, relativeColors }) => {
      if (baseColor && relativeColors) {
        relativeColors.forEach(({ namePosfix, colorDiff }) => {
          document.documentElement.style.setProperty(
            `${cssVariableName}${namePosfix}`,
            computeRelativeHSLColor(baseColor, colorDiff)
          );
        });
      }
    });
  }

  // getters
  const isSignUpAvailable = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.SIGN_UP)
  );
  const isPurchaseAvailable = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.PURCHASE)
  );
  const highlightColor = computed(() => {
    const configColor = growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.HIGHLIGHT_COLOR,
      HIGHLIGHTED_DEFAULT_TEXT_COLOR
    );
    return isValidHexColor(configColor)
      ? configColor
      : HIGHLIGHTED_DEFAULT_TEXT_COLOR;
  });
  const isApiResponseCachingEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.API_RESPONSE_CACHING)
  );
  const closedBetaMessageTitle = computed(() =>
    growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.CLOSED_BETA_MESSAGE_TITLE,
      'Thank you for your interest in our product.'
    )
  );
  const closedBetaMessageText = computed(() =>
    growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.CLOSED_BETA_MESSAGE_TEXT,
      'We are currently in a closed-beta. You can apply to be a part of it by filling out an application. If you think this is a mistake, please confirm the email address you used {{ user.email }} against the one you signed up for the beta with.'
    )
  );
  const closedBetaLink = computed(() =>
    growthbook.value?.getFeatureValue(eFEATURE_FLAG.CLOSED_BETA_LINK, '')
  );
  const isLayoutModeSettingsEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.IS_LAYOUT_MODE_SETTINGS_ENABLED)
  );
  const chatWithRebinderTooltipText = computed(() =>
    growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.CHAT_WITH_REBINDER_TOOLTIP_TEXT,
      'Chat with Rebinder'
    )
  );
  const chatWithAssistantTooltipText = computed(() =>
    growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.CHAT_WITH_ASSISTANT_TOOLTIP_TEXT,
      'Chat with Assistant'
    )
  );
  const isFullScreenModeEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.IS_FULL_SCREEN_MODE_ENABLED)
  );
  const isSubscriptionEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.IS_SUBSCRIPTION_ENABLED)
  );
  const isReflectionModeEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.IS_REFLECTION_MODE_ENABLED)
  );
  const isRateResponseEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.IS_RATE_RESPONSE_ENABLED)
  );
  const isReflectionSettingsEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.IS_REFLECTION_SETTINGS_ENABLED)
  );
  const isArticlesEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.ENABLE_ARTICLES)
  );
  const isArticleTextInsertionEnabled = computed(() =>
    growthbook.value?.isOn(eFEATURE_FLAG.ARTICLE_TAGS_INSERTION_ENABLED)
  );
  const highlightColor1 = computed(() =>
    growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.HIGHLIGHT_COLOR_1,
      HIGHLIGHT_DEFAULT_COLOR_1
    )
  );
  const highlightColor2 = computed(() =>
    growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.HIGHLIGHT_COLOR_2,
      HIGHLIGHT_DEFAULT_COLOR_2
    )
  );
  const highlightColor3 = computed(() =>
    growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.HIGHLIGHT_COLOR_3,
      HIGHLIGHT_DEFAULT_COLOR_3
    )
  );
  const highlightColor4 = computed(() =>
    growthbook.value?.getFeatureValue(
      eFEATURE_FLAG.HIGHLIGHT_COLOR_4,
      HIGHLIGHT_DEFAULT_COLOR_4
    )
  );

  // utils
  function $reset() {
    growthbook.value = null;
  }
  return {
    growthbook: readonly(growthbook),
    initStore,
    setDefaultHighlightColors,
    isSignUpAvailable,
    isPurchaseAvailable,
    highlightColor,
    isApiResponseCachingEnabled,
    closedBetaMessageTitle,
    closedBetaMessageText,
    closedBetaLink,
    isLayoutModeSettingsEnabled,
    chatWithRebinderTooltipText,
    chatWithAssistantTooltipText,
    isFullScreenModeEnabled,
    isSubscriptionEnabled,
    isReflectionModeEnabled,
    isRateResponseEnabled,
    isReflectionSettingsEnabled,
    isArticlesEnabled,
    isArticleTextInsertionEnabled,
    [eHIGHLIGHT_COLOR.COLOR_1]: highlightColor1,
    [eHIGHLIGHT_COLOR.COLOR_2]: highlightColor2,
    [eHIGHLIGHT_COLOR.COLOR_3]: highlightColor3,
    [eHIGHLIGHT_COLOR.COLOR_4]: highlightColor4,
    $reset,
  };
});
