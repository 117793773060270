export default {
  mounted(
    el: HTMLImageElement,
    binding: { value: { url: string; formats: Array<string> } }
  ) {
    const loadImage = () => {
      if (binding.value.url && binding.value.formats) {
        el.srcset = binding.value.formats
          .map((format) => `${binding.value.url}.${format}`)
          .join(', ');
      }
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    });

    observer.observe(el);
  },
};
