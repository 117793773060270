export const saveIfUnique = (key: string, onSave: () => void) => {
  if (localStorage.getItem(key) === null) {
    localStorage.setItem(key, 'true');
    onSave?.();
  }
};

export const setLocationToExitFromReader = (book_slug: string, url: string) => {
  localStorage.setItem(`exit_reader_location_${book_slug}`, url);
};

export const getLocationToExitFromReader = (book_slug: string) => {
  return localStorage.getItem(`exit_reader_location_${book_slug}`) || '/';
};

export const removeAllExitFromReaderLocations = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('exit_reader_location_')) {
      localStorage.removeItem(key);
    }
  });
};

export const setTrialCode = (code: string) => {
  localStorage.setItem('trial_code', code);
};

export const getTrialCode = () => {
  return localStorage.getItem('trial_code');
};

export const removeTrialCode = () => {
  localStorage.removeItem('trial_code');
};

export const setToggleSuggestQuestions = (value: string) => {
  localStorage.setItem('chat__show-message-suggestions', value);
};

export const getToggleSuggestQuestions = (): boolean => {
  const storedValue = localStorage.getItem('chat__show-message-suggestions');
  return !storedValue || storedValue === 'true';
};
