import { apiFetch } from '@/utils/api';
import type { iUser } from '@/types/apiModels';
import { eAPI_METHOD } from '@/types/enums';
import { getTrialCode, removeTrialCode } from '@/utils/localStorage';

// Get user's data
let apiGetUserController: AbortController;
export const apiGetUser = async () => {
  apiGetUserController?.abort?.();
  apiGetUserController = new AbortController();

  const trial_code = getTrialCode();

  const res = await apiFetch<iUser>('users/me', {
    method: eAPI_METHOD.GET,
    signal: apiGetUserController.signal,
    customOptions: {
      handleLocalError: true,
    },
    query: {
      ...(trial_code ? { trial_code } : {}),
    },
  });

  if (trial_code) {
    removeTrialCode();
  }

  return res;
};

// Update user's data
export const apiUpdateUser = async (body: Partial<iUser>) => {
  const res = await apiFetch<iUser>('users/me', {
    method: eAPI_METHOD.PATCH,
    body,
  });

  return res;
};

// Get trial link code
export const apiGetTrialLink = async () => {
  const res = await apiFetch<{ code: string }>('users/me/trial-redirect', {
    method: eAPI_METHOD.GET,
  });

  return res?.code;
};
